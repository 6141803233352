function sendStats(category, action, label, value) {
    if (value) {
        WP.stat.show(wp_sn, {
            "category": category,
            "label": label,
            "value": value,
            "asproduct": asproduct,
            "asorderid": asorderid,
            "asname": asname
        }, action);
        gtag('event', action + '-' + value, {
            'event_category': category,
            'event_label': label,
        });
        
    } else {
        WP.stat.show(wp_sn, {
            "category": category,
            "label": label,
            "asproduct": asproduct,
            "asorderid": asorderid,
            "asname": asname
        }, action);
        gtag('event', action, {
            'event_category': category,
            'event_label': label
        });
        
    }
}