var checkView = function () {
    $('.__js_animate').each(function () {
        var hT = $(this).offset().top,
            hH = $(this).outerHeight() / 1.8,
            wH = $(window).height(),
            wS = $(window).scrollTop();

        if (wS > (hT + hH - wH)) {
            $(this).addClass('__js_inView')
        }
    })
}

if ($('.__js_animate').length > 0) {
    checkView();
    $(window).scroll(function () {
        //will trigger when your element comes into viewport
        checkView();
    })
}