var sbm = document.querySelector('.competition-form .submit');

if (sbm !== null) {
    sbm.addEventListener("click", function (e) {
        var isCorrect = true;
        e.preventDefault();
        var inputs = document.querySelectorAll('.competition-form .blank input');
        var textarea = document.querySelector('.competition-form .blank textarea');
        var errors = document.querySelectorAll('.competition-form .error');
        var checkboxes = document.querySelectorAll('.competition-form input:checked');

        //email validation
        function validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        //get position of element
        function getPosition(element) {
            var xPosition = 0;
            var yPosition = 0;

            while (element) {
                xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
                yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
                element = element.offsetParent;
            }

            return {
                x: xPosition,
                y: yPosition
            };
        }

        document.querySelector('.competition-form .error-checkbox').style.display = "none";
        errors.forEach(function (error) {
            error.style.display = "none";
        })

        if (checkboxes.length < 3) {
            document.querySelector('.competition-form .error-checkbox').style.display = "block";
            isCorrect = false;
            var scrollY = getPosition(document.querySelector('.rodo--container')).y - 180;
            window.scrollTo({
                top: scrollY,
                left: 0,
                behavior: 'smooth'
            });
        }

        inputs.forEach(function (input) {
            if (!input.value) {
                document.querySelector('.competition-form .error-' + input.getAttribute('name')).style.display = "block";
                isCorrect = false;
                var scrollY = getPosition(document.querySelector('#competition')).y - 180;
                window.scrollTo({
                    top: scrollY,
                    left: 0,
                    behavior: 'smooth'
                });

            } else if (input.name == "email") {
                var msg;
                if ($('html').attr('lang') === 'en') {
                    msg = 'This field is required';
                } else {
                    msg = 'Podaną wartością musi być email';
                }
                if (!validateEmail(input.value)) {
                    document.querySelector('.competition-form .error-' + input.getAttribute('name')).innerHTML = msg;
                    document.querySelector('.competition-form .error-' + input.getAttribute('name')).style.display = 'block';
                    isCorrect = false;
                    var scrollY = getPosition(document.querySelector('#competition')).y - 180;
                    window.scrollTo({
                        top: scrollY,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
            } else if (input.name == "phone") {
                if (9 > input.value.length || input.value.lenght < 15) {
                    var msg;
                    if ($('html').attr('lang') === 'en') {
                        msg = 'This field is required';
                    } else {
                        msg = 'Podaną wartością musi być prawidłowy numer telefonu';
                    }
                    document.querySelector('.competition-form .error-' + input.getAttribute('name')).innerHTML = msg;
                    document.querySelector('.competition-form .error-' + input.getAttribute('name')).style.display = 'block';
                    isCorrect = false;
                    var scrollY = getPosition(document.querySelector('#competition')).y - 180;
                    window.scrollTo({
                        top: scrollY,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
            }
        })
        if (!textarea.value) {
            document.querySelector('.competition-form .error-' + textarea.getAttribute('name')).style.display = "block";
            isCorrect = false;
            var scrollY = getPosition(document.querySelector('#competition')).y - 180;
            window.scrollTo({
                top: scrollY,
                left: 0,
                behavior: 'smooth'
            });
        }

        if (isCorrect) {
            var url = document.querySelector('.form form').dataset.url;
            var serializeData = $('#competition').serialize();
            $.ajax({
                url: url,
                type: "POST",
                username: "w",
                password: "partner",
                data: serializeData
            }).done(function (response) {
                var message = '';
                if ($('html').attr('lang') === 'en') {
                    message = 'Message sent';
                } else {
                    message = 'Wysłano wiadomość';
                }
                $('.hover_bkgr_fricc div p').text(message);
                $('.hover_bkgr_fricc div p').css("color", "green");
                $('.hover_bkgr_fricc').show();
                inputs.forEach(function (input) {
                    input.value = "";
                })
                textarea.value = "";
                checkboxes.forEach(function (checkbox) {
                    checkbox.checked = false
                })
            }).fail(function (response) {
                var message = 'Błąd';
                if ($('html').attr('lang') === 'en') {
                    message = 'Error';
                }
                if (response.status >= 500) {
                    message = 'Problem techniczny, prosimy spróbować za chwilę';
                    $('.hover_bkgr_fricc div p').text(message);
                    $('.hover_bkgr_fricc div p').css("color", "#a94442");
                    $('.hover_bkgr_fricc').show();
                }
                if (500 > response.status >= 400) {
                    message = 'Błędnie wypełniony formularz';
                    $('.hover_bkgr_fricc div p').text(message);
                    $('.hover_bkgr_fricc div p').css("color", "#a94442");
                    $('.hover_bkgr_fricc').show();
                } else if (response.status == 422) {
                    message = response.responseJSON.errors['g-recaptcha-response'][0];
                    $('.hover_bkgr_fricc div p').text(message);
                    $('.hover_bkgr_fricc div p').css("color", "#a94442");
                    $('.hover_bkgr_fricc').show();
                } else {
                    $('.hover_bkgr_fricc div p').text(message);
                    $('.hover_bkgr_fricc div p').css("color", "#a94442");
                    $('.hover_bkgr_fricc').show();
                }
            })
        }
    })

}


var rodoinfo = document.querySelector('.rodo--container .rodo--info span');

if (rodoinfo !== null) {
    rodoinfo.addEventListener("click", function () {
        document.querySelector('.rodo--container .rodo--info--full').classList.remove('hidden')
        this.style.display = "none"

    })
}

var rodoinfo1 = document.querySelector('.rodo--container .rodo--info--full span');
if (rodoinfo1 !== null) {
    rodoinfo1.addEventListener("click", function () {
        document.querySelector('.rodo--container .rodo--info--full').classList.add('hidden')
        document.querySelector('.rodo--container .rodo--info span').style.display = "inline";
    })
}
