'use strict';

$.extend($.easing, {
    easeOutExpo: function (x, t, b, c, d) {
        return (t == d) ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b
    },
    easeInOutExpo: function (x, t, b, c, d) {
        if (t == 0) return b;
        if (t == d) return b + c;
        if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
        return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
    }
});
//Obiekt zbierający informacje o elementach typu banner, których statystyki należy wysłać w unload
//owlstats ga360 slider stats slider

// var owl = $(".owl-carousel").each(function () {
//     //sprawdzamy, czy jest ustawiony id dla statystyk
//     var ga360id = $(this).data('ga360-id');
//     if (ga360id === undefined) {
//         console.log('%c dodać ga360-id do slidera #' + $(this).data('gallery'), 'background: #553311; padding: 3px; color: #ffaaaa')
//     } else {
//         statElementCollector[ga360id] = {
//             id: ga360id,
//             moduleName: $(this).data('gallery'),
//             viewedCount: 0,
//             itemCount: $(this).find('.owl-item:not(.cloned)').length
//         }
//     };
//     $(this).statVievedSlide = [];
//     //pozycje
//     $(this).find('.owl-item:not(.cloned)').each(function (n) {
//         $(this).attr('idx', n + 1);
//     });
//     slidePositionVieved[ga360id] = {};
// });

var owl = $(".owl-carousel");

var owlLogo = $(".owl-logo");

if (!!owlLogo.length)
owlLogo.owlCarousel({
    loop: true,
    // stagePadding: 30,
    nav: false,
    // center: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    mouseDrag: false,
    touchDrag: false,
    responsive: {
        0: {
            items: 3,
            margin: 20
        },
        500: {
            items: 4,
            margin: 30
        },
        // 641: { items: 3, margin: 0 },
        881: {
            items: 6,
            margin: 30
        }
    }
});

var viewData = [];


// flaga dla urządzeń z touch:
if ('ontouchstart' in document.documentElement) {
    var deviceTouch = true;
}

// flaga dla scrollowania:
var isScrolligFlag = false;
var idTS;

window.addEventListener('scroll', function () {
    isScrolligFlag = true;
    if (idTS) {
        clearTimeout(idTS);
    }
    idTS = setTimeout(function () {
        idTS = null;
        isScrolligFlag = false;
    }, 450);
});

$('[data-st-click]').on('click auxclick contextmenu', function () {
    var T = $(this).data("st-click");
    if (T) {
        if (T.value) {
            sendStats(T.category, T.action, T.label, T.value)
        } else {
            sendStats(T.category, T.action, T.label);
        }
    }
});

$.extend($.easing, {
    easeOutExpo: function (x, t, b, c, d) {
        return (t == d) ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b
    },
    easeInOutExpo: function (x, t, b, c, d) {
        if (t == 0) return b;
        if (t == d) return b + c;
        if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
        return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
    }
});
